<script setup lang="ts">
import UISpinnerNew from '@/components/ui/UISpinnerNew/UISpinnerNew.vue';
import { isEmpty, filter, isUndefined } from 'lodash';
import { computed, nextTick, onMounted, ref, unref, watch } from 'vue';
import { useStore } from 'vuex';
import L from 'leaflet';
import 'leaflet.markercluster';
import { antPath } from 'leaflet-ant-path';
import * as markerUtils from './utils/marker.ts';
import * as geojsonUtils from './utils/geo-json.ts';
import * as antPathUtils from './utils/ant-path';
import * as circleUtils from './utils/circle';
import * as legendUtils from './utils/legend';
import * as shapeUtils from '@/components/geofence/map/utils/shape';
import { mapCenter } from '@/config/constants';
import { reverseCoords } from '@/utils/coords';
import * as layerUtils from '@/utils/map/layer';
import { isRouteForGpxAdmin } from '@/utils/rules';
import * as events from '@/events';
import { useRoute, useRouter } from 'vue-router';
import { useDevice } from '@/composables/useDevice';
import { usePosition } from '@/composables/usePosition.ts';
import { storeToRefs } from 'pinia';
import isNil from 'lodash/isNil';
import { addSeparator } from '@/utils/map/layer';
import { useFilter } from '@/composables/useFilter.ts';
import { useMap } from '@/composables/useMap.ts';

const props = defineProps<{
  view: string;
}>();

const store = useStore();
const route = useRoute();
const router = useRouter();
const mapStore = useMap();

const positionStore = usePosition();
const { getPositionByDeviceId } = positionStore;

const {
  isLoading,
  computedDevices,
  computedPositions,
  singlePositionsHistory,
  clusterPositionsHistory,
  stopsHistory,
  isAutoRefreshing,
} = storeToRefs(mapStore);

const deviceStore = useDevice();
const { deviceView } = storeToRefs(deviceStore);
const { backupDevicesFromMap, getById: getDeviceById } = deviceStore;

const filterStore = useFilter();

const isPageLoading = ref(true);

const isNewSingleDate = ref(false);

const dbClickTimer = ref(null);
const map = ref(null);
const mainLayers = ref({});
const overlays = ref({});
const layerControl = ref(null);
const fakeMarkersGroup = ref(L.layerGroup([]));
const marker = ref(null);
const markers = ref(null);
const tracksPath = ref(null);
const trackPoints = ref(L.layerGroup([]));
const tracksGroup = ref(L.layerGroup([]));
const stopsGroup = ref(L.layerGroup([]));
const cellAccuracy = L.layerGroup([]);
const wifiAccuracy = L.layerGroup([]);

// Groups below are only used for layer control.
// Actual markers are being rendered via `markers`
const cellGroup = L.layerGroup([]);
const wifiGroup = L.layerGroup([]);
const gpsGroup = L.layerGroup([]);
const bleGroup = L.layerGroup([]);

const shapes = ref(L.layerGroup([]));
const stopCircles = ref(null);

const { setOverlayVisible } = filterStore;
const { dateFilter } = storeToRefs(filterStore);

const canUseBleFeature = computed(() => store.getters['auth/canUseBleFeature']);
const geofences = computed(() => store.getters['geofence/all']);
const isGpxAdminPage = computed(() => isRouteForGpxAdmin(route));

watch(
  () => props.view,
  (newView, oldView) => {
    setActiveLayer(newView, oldView);
  },
);
watch(
  () => dateFilter.value,
  (n, o) => {
    if (n !== o) {
      isNewSingleDate.value = true;
    }
  },
);
watch(
  () => deviceView.value?.current_position?.location,
  () => {
    if (props.view === 'single') {
      updateSingleMarker(marker);
    }
  },
);
watch(
  () => computedPositions.value,
  () => {
    if (props.view === 'cluster') {
      updateCurrentPositions();
    }
  },
);
watch(
  () => clusterPositionsHistory.value,
  (n) => {
    if (isUndefined(n) || props.view !== 'cluster') return;
    layerUtils.useLoadingOverlay({
      fn: updateClusterTracksData,
      setOverlay: setOverlay,
      delay: 100,
    });
  },
);
watch(
  () => singlePositionsHistory.value,
  (n) => {
    nextTick(() => {
      if (isUndefined(n) || props.view !== 'single') return;
      layerUtils.useLoadingOverlay({
        fn: updateSingleTracksData,
        setOverlay: setOverlay,
        delay: 400,
      });
    });
  },
);
watch(
  () => stopsHistory.value,
  () => {
    updateStopsData();
  },
);
watch(
  () => geofences.value,
  () => {
    updateGeofencesData();
  },
);
watch(
  () => computedDevices.value,
  (n, o) => {
    if (props.view === 'single') {
      return updateSingleMarker(marker);
    }
    if (props.view === 'cluster') {
      if (n?.length === o?.length && markers.value) {
        return;
      }
      backupDevicesFromMap(n);
      updateCurrentPositions();
    }
  },
);

onMounted(() => {
  setupLeafletMap();
  setupLegend();
  setupGeofences();
  setActiveLayer(props.view);
  setOverlay(false);
});

const setOverlay = (value: boolean) => {
  isPageLoading.value = value;
};
const updateSingleMarker = (value) => {
  if (!marker.value) return;
  const id = +route.params.id;
  const position = getPositionByDeviceId(id);

  const device = getDeviceById(id);
  const content = isGpxAdminPage.value
    ? markerUtils.getPopupContent(device, position)
    : markerUtils.getPopupContentWithCreate(device, position);
  if (position) {
    markerUtils.moveToPosition(marker.value, position);
  }
  const popup = unref(value).getPopup();
  popup.setContent(content);
  unref(map).panTo(unref(marker)._latlng);
};

const clearFixesGroupLayers = () => {
  unref(tracksGroup).clearLayers();
  unref(cellAccuracy).clearLayers();
  unref(wifiAccuracy).clearLayers();
  unref(cellGroup).clearLayers();
  unref(wifiGroup).clearLayers();
  unref(gpsGroup).clearLayers();
  unref(bleGroup).clearLayers();
};

const setActiveLayer = (newView, oldView?) => {
  isAutoRefreshing.value = false;
  if (newView === 'cluster') {
    if (oldView === 'single') {
      clearFixesGroupLayers();
      drawClusterMarker();
      updateClusterTracksData();
    }

    if (marker.value) {
      unref(map).removeLayer(unref(marker));
    }
  }
  if (newView === 'single') {
    if (markers.value) {
      unref(markers).clearLayers();
      unref(map).removeLayer(unref(markers));
    }

    clearFixesGroupLayers();

    if (!deviceView.value) {
      return;
    }

    const position = getPositionByDeviceId(deviceView.value.id);
    if (!position) return;
    setupCluster();

    createSingleMarker(deviceView.value, position);
    unref(map).addLayer(unref(marker));

    setSingleFixes();
    setSingleView(unref(marker)._latlng);
    unref(marker).fire('click');
  }
};

const setupLeafletMap = () => {
  mainLayers.value = layerUtils.getLayers();
  const { id } = layerUtils.useLocalStorageLayer();

  overlays.value = {
    'Show current location': unref(fakeMarkersGroup),
    'Accuracy - Cell': unref(cellAccuracy),
    'Accuracy - WiFi': unref(wifiAccuracy),
    'Show geofences': unref(shapes),
    'Show tracks': unref(tracksGroup),
    'Show stops': unref(stopsGroup),
    Cell: unref(cellGroup),
    WiFi: unref(wifiGroup),
    GPS: unref(gpsGroup),
    BLE: unref(bleGroup),
  };
  map.value = L.map('map', {
    layers: [unref(mainLayers)[id], unref(fakeMarkersGroup)],
    center: mapCenter,
    zoomControl: false,
    zoom: 5,
    minZoom: 1,
  });
  L.control.zoom({ position: 'topright' }).addTo(unref(map));

  layerControl.value = L.control
    .layers(unref(mainLayers), unref(overlays), {
      position: 'topleft',
    })
    .addTo(unref(map));

  nextTick(() => {
    addSeparator(6, 'Filter Fix Types');
  });

  setupFakeMarkersOverlay();
  setupOverlay('cellaccuracy', cellAccuracy);
  setupOverlay('wifiaccuracy', wifiAccuracy);
  setupOverlay('cellfixes', cellGroup);
  setupOverlay('wififixes', wifiGroup);
  setupOverlay('gpsfixes', gpsGroup);
  setupOverlay('blefixes', bleGroup);
  setupOverlay('tracksGroup', tracksGroup);
  setupOverlay('stops', stopsGroup);

  listenZoom();
  listenMove();
  listenOverlayChange();
  listenLayerChange(unref(mainLayers));
};

const setupLegend = () => {
  const legend = legendUtils.createLegend();
  legend.addTo(unref(map));
};

const setupOverlay = (name: string, overlay) => {
  const { is } = layerUtils.useLocalStorageOverlay(name);
  if (is) {
    unref(overlay).addTo(unref(map));
    setOverlayVisible(name, true);
  } else {
    setOverlayVisible(name, false);
  }
};

const setupFakeMarkersOverlay = () => {
  const { is } = layerUtils.useLocalStorageOverlay('markers');
  if (!is) {
    toggleShowMarkers(false);
    unref(map).removeLayer(unref(fakeMarkersGroup));
  }
};

const setupCluster = async () => {
  const panes = [
    'cellaccuracy',
    'wifiaccuracy',
    'cellfixes',
    'wififixes',
    'gpsfixes',
    'blefixes',
    'tracksGroup',
    'stops',
    'positions',
  ];
  for (const [index, pane] of panes.entries()) {
    unref(map).createPane(pane);
    unref(map).getPane(pane).style.zIndex = 410 + index;
  }
  markers.value = L.markerClusterGroup(markerUtils.clusterOptions);
};

const filterVisiblePositions = (positions) => {
  const visibility = {
    gps: layerUtils.useLocalStorageOverlay('gpsfixes').is,
    wifi: layerUtils.useLocalStorageOverlay('wififixes').is,
    cell: layerUtils.useLocalStorageOverlay('cellfixes').is,
    ble: layerUtils.useLocalStorageOverlay('blefixes').is,
  };
  return filter(positions, (position) => visibility[position.fix]);
};

const drawClusterMarker = (resetBounds = true) => {
  setOverlay(true);
  filterVisiblePositions(computedPositions.value).forEach((position) => {
    createClusterMarker(position);
    createAccuracyFix(position);
  });
  resetBounds && setClusterView();
  setOverlay(false);
};

const setupGeofences = () => {
  const { is } = layerUtils.useLocalStorageOverlay('geofences');
  geofences.value.forEach((geofence) => createClusterShape(geofence));
  if (is) {
    unref(shapes).addTo(unref(map));
  }
};

const setClusterView = () => {
  unref(map).addLayer(unref(markers));

  if (unref(isAutoRefreshing)) {
    return;
  }

  const bounds = unref(markers).getBounds();
  if (isEmpty(bounds) || !bounds.isValid()) {
    return;
  } else {
    unref(map).fitBounds(bounds, { maxZoom: 13 });
  }
};

const setSingleView = (latlng) => {
  const zoomLevel = unref(map).getZoom();
  if (zoomLevel < 15) {
    unref(map).setView(latlng, 17);
  }
  const bounds = unref(map).getBounds();
  unref(map).fitBounds(bounds);
};

const setSingleFixes = () => {
  const position = getPositionByDeviceId(deviceView.value.id);
  if (!position) return;
  createAccuracyFix(position);
};

const createAccuracyFix = (position) => {
  if (position.fix === 'cell') {
    const circle = circleUtils.createCellCircle(position, 1);
    unref(circle).addTo(unref(cellAccuracy));
  }
  if (position.fix === 'wifi') {
    const circle = circleUtils.createWifiCircle(position, 1);
    unref(circle).addTo(unref(wifiAccuracy));
  }
};

const createClusterMarker = (position) => {
  const getDevice = () => getDeviceById(position.device_id);
  const marker = markerUtils.createMarker(
    { id: position.device_id },
    position,
    {
      isNewIcon: canUseBleFeature.value,
    },
  );

  marker
    .bindPopup('', {
      closeButton: false,
      minWidth: 200,
    })
    .openPopup()
    .on('mouseover', function () {
      const content = markerUtils.getPopupContent(getDevice(), position);
      this.getPopup().setContent(content);
      this.openPopup();
    })
    .on('click', (event) => {
      if (dbClickTimer.value !== null) {
        return;
      }
      dbClickTimer.value = setTimeout(() => {
        dbClickTimer.value = null;
        handleClusterMarkerClick({ event });
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(dbClickTimer.value);
      dbClickTimer.value = null;
    });
  marker.addTo(unref(markers));
};

const createSingleMarker = async (forDevice: any, position?) => {
  if (!position) return;
  const content = isGpxAdminPage.value
    ? markerUtils.getPopupContent(forDevice, position)
    : markerUtils.getPopupContentWithCreate(forDevice, position);
  marker.value = markerUtils.createMarker(forDevice, position, {
    isNewIcon: canUseBleFeature.value,
  });

  marker.value.bindPopup(content, {
    closeButton: false,
    minWidth: 200,
    autoPan: false,
  });
};

const createClusterShape = (geofence) => {
  const shape = shapeUtils.createShape(geofence);
  shape
    .on('click', () => {
      if (dbClickTimer.value !== null) {
        return;
      }
      dbClickTimer.value = setTimeout(() => {
        dbClickTimer.value = null;
        handleClusterShapeClick(geofence.id);
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(dbClickTimer.value);
      dbClickTimer.value = null;
    });
  const content = shapeUtils.getTooltipContent({ geofence });

  L.tooltip({
    interactive: true,
    permanent: true,
    opacity: 0.7,
  })
    .on('click', () => {
      if (dbClickTimer.value !== null) {
        return;
      }
      dbClickTimer.value = setTimeout(() => {
        dbClickTimer.value = null;
        handleClusterShapeClick(geofence.id);
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(dbClickTimer.value);
      dbClickTimer.value = null;
    })
    .setLatLng(L.latLng([geofence.lat, geofence.lng]))
    .setContent(content)
    .openOn(unref(shapes));

  shape.addTo(unref(shapes));
};

const handleClusterMarkerClick = ({ event }) => {
  router.push({
    name: 'DeviceDetailsView',
    params: {
      id: event.target.options.id,
    },
  });
};

const handleClusterShapeClick = (id) => {
  if (isGpxAdminPage.value) return;
  router.push({
    name: 'GeofenceDetailsView',
    params: {
      id,
    },
  });
};

const updateClusterTracksData = () => {
  unref(tracksGroup).clearLayers();
  if (isEmpty(clusterPositionsHistory)) {
    return;
  }

  let tempFC = [];
  let colors = [];

  clusterPositionsHistory.value.forEach((position) => {
    if (!position?.path.length) return;
    if (isEmpty(colors)) {
      colors = geojsonUtils.createColors();
    }
    const color = colors.pop();

    const line = geojsonUtils.createPositionLine({
      position,
      color,
      map: unref(map),
    });
    line.addTo(unref(tracksGroup));
    const fc = geojsonUtils.convertToPositionPointFC({
      path: position.path,
      name: position.name,
    });
    tempFC.push(fc);
  });
  const { geoJSONPoints } =
    geojsonUtils.createPositionPointsAndExtractFixes(tempFC);
  trackPoints.value = geoJSONPoints;
  unref(trackPoints).addTo(unref(tracksGroup));
};

const updateSingleTracksData = () => {
  if (!deviceView.value) return;
  if (isEmpty(singlePositionsHistory.value)) {
    unref(tracksGroup).clearLayers();
    unref(trackPoints).clearLayers();
    return;
  }

  clearFixesGroupLayers();
  const coordsForAntPath = singlePositionsHistory.value.map((h) =>
    reverseCoords(h.location),
  );
  tracksPath.value = antPath(coordsForAntPath, {
    ...antPathUtils.getStyle(unref(map), unref(mainLayers)),
    delay: 4e3,
    weight: 4,
    hardwareAccelerated: true,
  });
  const fc = geojsonUtils.convertToPositionPointFC({
    path: singlePositionsHistory?.value,
    name: deviceView.value.name,
  });

  const {
    geoJSONPoints,
    cellFixes: extractedCellFixes,
    wifiFixes: extractedWifiFixes,
  } = geojsonUtils.createPositionPointsAndExtractFixes(fc);

  trackPoints.value = geoJSONPoints;
  extractedCellFixes.addTo(unref(cellAccuracy));
  extractedWifiFixes.addTo(unref(wifiAccuracy));

  unref(trackPoints).addTo(unref(tracksGroup));
  unref(tracksPath).addTo(unref(tracksGroup));

  if (isNewSingleDate.value) {
    isNewSingleDate.value = false;
    const bounds = unref(tracksPath).getBounds();
    unref(map).fitBounds(bounds);
  }
};

const updateStopsData = () => {
  if (isNil(stopsHistory.value) || !marker.value) return;
  if (isEmpty(stopsHistory.value)) {
    unref(stopsGroup).clearLayers();
    return;
  }
  unref(stopsGroup).clearLayers();

  const featureCollection = geojsonUtils.convertToStopCircleFC(
    stopsHistory.value,
  );
  stopCircles.value = geojsonUtils.createStopCircles(featureCollection);
  unref(stopCircles).addTo(unref(stopsGroup));
};

const updateCurrentPositions = (resetBounds = true) => {
  if (markers.value) {
    clearFixesGroupLayers();
    unref(markers).clearLayers();
    unref(map).removeLayer(unref(markers));
  }
  setupCluster();
  drawClusterMarker(resetBounds);
  unref(map).addLayer(unref(markers));
};

const updateGeofencesData = () => {
  unref(shapes).clearLayers();
  unref(map).removeLayer(unref(shapes));
  setupGeofences();
};

const toggleShowMarkers = (value: boolean) => {
  const display = value ? 'block' : 'none';
  const markerPane = document.querySelector('.leaflet-marker-pane');
  const shadowPane = document.querySelector('.leaflet-shadow-pane');
  [markerPane, shadowPane].forEach((pane) =>
    pane.setAttribute('style', `display: ${display}`),
  );
};

/*
 * Map Listeners
 */
const listenZoom = () => {
  unref(map).on('zoomend', () => {
    const { set } = layerUtils.useLocalStorageZoom();
    const zoom = unref(map).getZoom();
    set(zoom);
  });
};

const listenMove = () => {
  unref(map).on('moveend', () => {});
};

const listenLayerChange = (layers) => {
  const { set } = layerUtils.useLocalStorageLayer();
  unref(map).on('baselayerchange', (e) => {
    set(e.name);
    events.trigger(events.names.TRACKER_MAP_VIEW_CHANGED, { view: e.name });

    if (tracksPath.value) {
      const style = antPathUtils.getStyle(unref(map), layers);
      tracksPath.value.setStyle(style);
    }
    if (trackPoints.value) {
      unref(trackPoints).eachLayer(function (layer) {
        const { name, speed } = layer.feature.properties;
        if (name === 'Position') {
          layer.setStyle(geojsonUtils.getPositionStyle(e.name, speed));
        }
      });
    }
  });
};

const listenOverlayChange = () => {
  const mapLabelOverlay = {
    'Show current location': {
      overlay: 'markers',
      set: layerUtils.useLocalStorageOverlay('markers').set,
    },
    'Accuracy - Cell': {
      overlay: 'cellaccuracy',
      set: layerUtils.useLocalStorageOverlay('cellaccuracy').set,
    },
    'Accuracy - WiFi': {
      overlay: 'wifiaccuracy',
      set: layerUtils.useLocalStorageOverlay('wifiaccuracy').set,
    },
    Cell: {
      overlay: 'cellfixes',
      fix: 'cell',
      set: (value) => {
        layerUtils.useLocalStorageOverlay('cellfixes').set(value);
        nextTick(() => updateCurrentPositions(false));
      },
    },
    WiFi: {
      overlay: 'wififixes',
      fix: 'wifi',
      set: (value) => {
        layerUtils.useLocalStorageOverlay('wififixes').set(value);
        nextTick(() => updateCurrentPositions(false));
      },
    },
    GPS: {
      overlay: 'gpsfixes',
      fix: 'gps',
      set: (value) => {
        layerUtils.useLocalStorageOverlay('gpsfixes').set(value);
        nextTick(() => updateCurrentPositions(false));
      },
    },
    BLE: {
      overlay: 'blefixes',
      fix: 'ble',
      set: (value) => {
        layerUtils.useLocalStorageOverlay('blefixes').set(value);
        nextTick(() => updateCurrentPositions(false));
      },
    },
    'Show geofences': {
      overlay: 'geofences',
      set: layerUtils.useLocalStorageOverlay('geofences').set,
    },
    'Show tracks': {
      overlay: 'tracksGroup',
      set: layerUtils.useLocalStorageOverlay('tracksGroup').set,
    },
    'Show stops': {
      overlay: 'stops',
      set: layerUtils.useLocalStorageOverlay('stops').set,
    },
  };

  unref(map).on('overlayadd', (e) => {
    if (e.name === 'Show current location') {
      toggleShowMarkers(true);
    }

    const config = mapLabelOverlay[e.name];
    if (config) {
      config.set(true);
      setOverlayVisible(config.overlay, true);
    }
  });
  unref(map).on('overlayremove', (e) => {
    if (e.name === 'Show current location') {
      toggleShowMarkers(false);
    }

    const config = mapLabelOverlay[e.name];
    if (config) {
      config.set(false);
      setOverlayVisible(config.overlay, false);
    }
  });
};
</script>

<template>
  <div class="relative z-0 size-full">
    <div
      v-show="isLoading || isPageLoading"
      class="absolute z-10 flex size-full cursor-wait items-center justify-center bg-white/20 backdrop-blur-[2px]"
    >
      <UISpinnerNew size="md" />
    </div>
    <div class="absolute inset-0 z-0 size-full" id="map" />
  </div>
</template>
