<script setup lang="ts">
import * as _ from 'lodash';
import NProgress from 'nprogress';
import { ref, computed, watch, unref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useAutoRefresh } from '@/use/auto-refresh';
import CommonRefreshIconButton from '@/components/_common/CommonRefreshIconButton.vue';
import CommonAutoRefreshButton from '@/components/_common/CommonAutoRefreshButton.vue';
import DeviceListFilters from './DeviceListFilters.vue';
import DeviceGroupListSearchBar from './DeviceGroupListSearchBar.vue';
import DeviceMapTracksLoader from '../map/DeviceMapTracksLoader.vue';
import { usePersonalLocalStorage } from '@/use/use-personal-local-storage';
import { formatTime } from '@/filters';
import GroupDetailsNavBar from '../details/GroupDetailsNavBar.vue';
import { differenceInDays, parseISO } from 'date-fns';
import formatDate from 'date-fns/format';
import { getFilterResultsByLink } from '@/utils/device-filter.ts';
import { useToast } from 'vue-toastification';
import { useDevice } from '@/composables/useDevice';
import { useGroup } from '@/composables/useGroup';
import { usePosition } from '@/composables/usePosition';
import { storeToRefs } from 'pinia';
import { fetchPositionsByAccount } from '@/router/fetchers.js';
import { usePositionHistory } from '@/composables/usePositionHistory.ts';
import { useFilter } from '@/composables/useFilter.ts';
import { useMap } from '@/composables/useMap.ts';

const toast = useToast();
const store = useStore();
const route = useRoute();

const filterStore = useFilter();
const positionHistoryStore = usePositionHistory();

const { positions } = storeToRefs(usePosition());
const { isAutoRefreshing } = storeToRefs(useMap());

const { setDateFilter } = filterStore;
const { dateFilter } = storeToRefs(filterStore);

const deviceStore = useDevice();
const { selectedIds, deviceIdsFromMap, devices, filter, resultDeviceIds } =
  storeToRefs(deviceStore);

const { fetchByAccountId, downloadByAccountId, downloadReportPositions } =
  deviceStore;

const groupStore = useGroup();
const { getById: getGroupById, getByIds: getGroupByIds } = groupStore;
const {
  resultDeviceIds: resultGroupDeviceIds,
  selectedIds: groupSelectedIds,
  searchedIds,
  groups,
} = storeToRefs(groupStore);

const { clearHistoryPositions, fetchHistoryPositionsByAccountAndDevices } =
  positionHistoryStore;

const isLoading = ref(false);

const accountId = store.getters['auth/accountId'];
const account = store.getters['auth/account'];
const geofences = computed(() => store.getters['geofence/all']);

const ids = computed(() => {
  if (route.name === 'DeviceListView') {
    return resultDeviceIds.value;
  }
  if (route.name === 'GroupListView' || route.name === 'GroupDetailsView') {
    return resultGroupDeviceIds.value;
  }
  return undefined;
});

const downloadAllIds = computed(() => {
  if (route.name === 'DeviceListView') {
    return null;
  }
  if (route.name === 'GroupListView') {
    const deviceIds = [];
    groups.value.forEach((group) => {
      deviceIds.push(group.device_ids);
    });
    return [...new Set(deviceIds.flat())];
  }
  if (route.name === 'GroupDetailsView') {
    const group = getGroupById(+route.params.id);
    return group.device_ids;
  }
  return [];
});
const downloadSearchedIds = computed(() => {
  if (deviceIdsFromMap.value?.length === 0) return [];
  if (route.name === 'DeviceListView') {
    return resultDeviceIds.value;
  }
  if (route.name === 'GroupListView') {
    const searchedGroups = getGroupByIds(searchedIds.value);
    const deviceIds = searchedGroups.map((item) => item.device_ids);
    const uniqueDeviceIds = [...new Set(deviceIds.flat())];
    return getFilterResultsByLink(uniqueDeviceIds, devices.value, filter);
  }
  if (route.name === 'GroupDetailsView') {
    const group = getGroupById(+route.params.id);
    if (group) {
      return getFilterResultsByLink(group.device_ids, devices.value, filter);
    }
  }
  return [];
});

const canSeeCancelledDevices = store.getters['auth/canSeeCancelledDevices'];

const dateModel = ref(
  unref(dateFilter).dateFrom
    ? [unref(dateFilter).dateFrom, unref(dateFilter).dateTo]
    : null,
);

const isSelectedDate = computed(() => {
  return unref(dateFilter).dateTo;
});

const fetchDevices = () => {
  const defaultStatuses = 'active,pending,pending-renewal,pending-cancellation';

  const subscriptionStatuses = canSeeCancelledDevices
    ? defaultStatuses.concat(',', 'cancelled,blocked')
    : defaultStatuses;

  return fetchByAccountId(accountId, {
    with_current_position: true,
    subscription_status: subscriptionStatuses,
  });
};
const fetchGeofences = () => store.dispatch('geofence/fetch');

const isDownloadPointsEnabled = computed(() => {
  return positions.value.size > 0;
});
const isControlsFeature = computed(() => {
  return store.getters['auth/accountSettingIsOn']('controls_available');
});
const downloadPoints = (ids) => {
  let fields = [
    'device.name',
    'device.serial',
    'address',
    'fix_time',
    'lat',
    'lng',
    'map',
    'battery',
  ];

  if (account?.device_triggers_count > 0) {
    fields = [
      ...fields,
      'device.triggers.min_humidity',
      'device.triggers.max_humidity',
      'device.triggers.min_temperature',
      'device.triggers.max_temperature',
      'temperature',
      'humidity',
    ];
  }

  const filters = [
    {
      field: 'account_id',
      value: accountId,
    },
    {
      field: 'only_current_positions',
      value: 1,
    },
    {
      field: 'offset',
      value: new Date().getTimezoneOffset(),
    },
  ];

  if (ids) {
    filters.push({
      field: 'device',
      conditions: [
        {
          field: 'id',
          value: ids,
        },
      ],
    });
  }

  downloadByAccountId(
    `trackers_${formatTime(new Date(), 'yyyy-MM-dd:HH:mm:ss')}`,
    {
      fields,
      filters,
    },
  );
};
const sendReportPositionsToEmail = (ids, dateFrom, dateTo) => {
  const filters = [
    {
      field: 'account_id',
      value: accountId,
    },
    {
      field: 'fix_time_from',
      value: dateFrom,
    },
    {
      field: 'fix_time_to',
      value: dateTo,
    },
    {
      field: 'offset',
      value: new Date().getTimezoneOffset(),
    },
  ];
  if (ids) {
    filters.push({
      field: 'device',
      conditions: [
        {
          field: 'id',
          value: ids,
        },
      ],
    });
  }

  let fields = [
    'device.name',
    'device.serial',
    'address',
    'fix_time',
    'map',
    'battery',
  ];

  if (account?.device_triggers_count > 0) {
    fields = [
      ...fields,
      'device.triggers.min_humidity',
      'device.triggers.max_humidity',
      'device.triggers.min_temperature',
      'device.triggers.max_temperature',
      'temperature',
      'humidity',
    ];
  }

  downloadReportPositions({
    type: 'positions',
    response_type: 'send_email',
    filters,
    fields,
  })
    .then(() => {
      store.dispatch('modal/close', 'DeviceDownloadPositionsModal');
      store.dispatch('modal/open', {
        name: 'DownloadConfirmationModal',
        params: {
          title: 'Device positions',
          body: 'Your CSV is being generated. Once ready, you will receive an email notification with the download link',
        },
      });
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'An error occurred');
    });
};

const refreshAll = () => {
  isLoading.value = true;
  isAutoRefreshing.value = true;
  NProgress.start();

  const stack = [fetchDevices(), fetchPositionsByAccount(accountId)];
  if (isSelectedDate.value) {
    const deviceIds = selectedIds.value?.length ? selectedIds : ids;
    stack.push(
      fetchHistoryPositionsByAccountAndDevices(accountId, unref(deviceIds)),
    );
  }

  if (unref(geofences).length === 0) {
    stack.push(fetchGeofences());
  }

  const promise = Promise.all(stack);
  promise.finally(() => {
    isLoading.value = false;
    NProgress.done();
  });
  return promise;
};

const { enable, disable, isEnabled, forcedRefresh } =
  useAutoRefresh(refreshAll);

const debouncedFetchHandler = _.debounce((ids) => {
  isLoading.value = true;
  fetchHistoryPositionsByAccountAndDevices(accountId, ids).finally(
    () => (isLoading.value = false),
  );
}, 500);

const isShowControls = usePersonalLocalStorage('trackers/showControls', false);
const showControls = () => {
  isShowControls.value = true;
  setDateFilter('', '');
};
const hideControls = () => {
  isShowControls.value = false;
  dateModel.value = null;
  clearHistoryPositions();
};

watch(
  [() => dateModel, () => ids],
  (n) => {
    if (isControlsFeature.value && isShowControls.value && n[0]?.value) {
      const [dateFrom, dateTo] = n[0].value;
      setDateFilter(dateFrom, dateTo);
      const ids = n[1].value;
      if (_.isUndefined(ids)) return;
      debouncedFetchHandler(ids);
    }
  },
  { deep: true },
);

function download({ ids, filter }) {
  if (isShowControls.value) {
    const dateFrom = parseISO(unref(dateFilter).dateFrom);
    const dateTo = parseISO(unref(dateFilter).dateTo);

    store.dispatch('modal/open', {
      name: 'DeviceDownloadPositionsModal',
      params: {
        disabled: differenceInDays(dateTo, dateFrom) > 90,
        dateFrom: formatDate(dateFrom, 'MMMM dd, yyyy'),
        dateTo: formatDate(dateTo, 'MMMM dd, yyyy'),
        filter,
        onDownloadControls() {
          sendReportPositionsToEmail(ids, dateFrom, dateTo);
        },
        onDownloadCurrentPositionOnly() {
          downloadPoints(ids);
        },
      },
    });
  } else {
    return downloadPoints(ids);
  }
}

function getDownloadActions() {
  const actions = [];
  const downloadAll = {
    icon: 'ArrowDownTrayIcon',
    label: 'All',
    tooltip: 'Download all',
    action: {
      mode: 'fn',
      fn: () => {
        download({ ids: downloadAllIds.value, filter: 'all' });
      },
    },
  };
  const downloadSearched = {
    icon: 'ArrowDownTrayIcon',
    label: 'Searched',
    tooltip: 'Download only searched',
    action: {
      mode: 'fn',
      fn: () => {
        download({ ids: downloadSearchedIds.value, filter: 'searched' });
      },
    },
  };
  const downloadSelected = {
    icon: 'ArrowDownTrayIcon',
    label: 'Selected',
    tooltip: 'Download only selected',
    action: {
      mode: 'fn',
      fn: () => {
        download({ ids: deviceIdsFromMap.value, filter: 'selected' });
      },
    },
  };

  if (route.name === 'DeviceListView') {
    actions.push(downloadAll, downloadSearched);
    if (selectedIds.value?.length) {
      actions.push(downloadSelected);
    }
    return actions;
  }
  if (route.name === 'GroupListView') {
    actions.push(downloadAll, downloadSearched);
    if (groupSelectedIds.value?.length) {
      actions.push(downloadSelected);
    }
    return actions;
  }
  if (route.name === 'GroupDetailsView') {
    actions.push(downloadAll, downloadSearched);
    if (selectedIds.value?.length) {
      actions.push(downloadSelected);
    }
    return actions;
  }
}
</script>

<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <GroupDetailsNavBar
      v-if="route.name === 'GroupDetailsView'"
      class="h-[64px] sm:h-[48px] sm:translate-y-2"
    />
    <DeviceGroupListSearchBar
      v-else
      class="h-[64px] sm:h-[48px] sm:translate-y-2"
    />
  </div>

  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div class="flex gap-2">
      <CommonRefreshIconButton
        :disabled="isLoading"
        :is-loading="isLoading"
        @click="forcedRefresh"
      />
      <CommonAutoRefreshButton
        :is-enabled="isEnabled"
        @enable="enable"
        @disable="disable"
      />
      <div class="relative">
        <DeviceListFilters />
      </div>
      <div
        v-if="isShowControls && isControlsFeature"
        class="hidden gap-x-1 sm:flex"
      >
        <UITooltip text="Turn off controls" placement="bottom">
          <UIButtonNew
            size="sm"
            variant="tertiary"
            icon="Cog6ToothIcon"
            @click="hideControls"
          >
            On
          </UIButtonNew>
        </UITooltip>
        <VDatePickerRange
          v-model="dateModel"
          :maxDate="true"
          :initialValue="dateModel"
          tooltip="Select range"
          utc
        />
        <DeviceMapTracksLoader v-if="isLoading" />
      </div>
      <UITooltip text="Turn on controls" placement="bottom">
        <UIButtonNew
          v-if="!isShowControls && isControlsFeature"
          size="sm"
          variant="tertiary"
          icon="Cog6ToothIcon"
          @click="showControls"
        >
          Off
        </UIButtonNew>
      </UITooltip>
    </div>

    <UIMenuButton
      size="sm"
      mainIcon="ArrowDownTrayIcon"
      mainLabel="All"
      :disabled="!isDownloadPointsEnabled"
      :options="getDownloadActions()"
      variant="tertiary"
      @click="download({ ids: downloadAllIds, filter: 'all' })"
    />
  </div>
</template>
